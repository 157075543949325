import React from "react";
import { withGoogleSheets } from "../components/withGoogleSheets";
import "./footer.scoped.scss";
import _ from 'lodash';

export default withGoogleSheets("Contact")((props) => {
  
  const phone = _.find(props.db.Contact, {id: 'phone'});
  const email = _.find(props.db.Contact, {id: 'email'});
  
  return (
    <footer>
      <p className="name">Hotel Villiam</p>
      <address>
        <div>
          <p>{_.get(_.find(props.db.Contact, {id: 'street'}), 'value')}</p>
          <p>{_.get(_.find(props.db.Contact, {id: 'city'}), 'value')}</p>
        </div>
        <div>
          <p><a href={`tel:${phone.href}`}>{phone.value}</a></p>
          <p><a href={`mailto:${email.href}`}>{email.value}</a></p>
        </div>
      </address>
    </footer>
  );
});
