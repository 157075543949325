import React from "react";
import { withGoogleSheets } from "../components/withGoogleSheets";
import _ from "lodash";

import img1 from "../assets/pakiety/1.jpg";

import ImageGallery from "../components/gallery";
import Logo from "../components/logo";

import "./pakiety.scoped.scss";

function PakietyPage(props) {
  const images = _.map([img1], (original) => ({
    original,
  }));

  const missing = _.find(props.db.Pakiety, { id: "missing" });
  

  const pakiety = _.map(
    _.omit(_.groupBy(props.db.Pakiety, "pakiet"), "null"),
    (items) => {
      return {
        name: _.find(items, { id: "name" }).value,
        header: _.find(items, { id: "header" }).value,
        list: _.map(_.filter(items, { id: "list" }), "value"),
        price: _.map(_.filter(items, { id: "price" }), "value"),
        note: _.find(items, { id: "note" }).value,
      };
    }
  );
  console.log(missing);

  return (
    <div>
      <div className="media-content">
        <h2>{_.get(_.find(props.db.Pakiety, { id: "header" }), "value")}</h2>
        <ImageGallery items={images} />
      </div>
      <div className="container text-content">
        <div className="container-text">
          {pakiety.length === 0
            ? <div dangerouslySetInnerHTML={{__html: missing.value}}/>
            : _.map(pakiety, ({ name, header, list, price, note }) => {
                return (
                  <div className="pakiet" key={name}>
                    <h3>{name}</h3>
                    {header.split(/\n/i).map((text, index) => (
                      <p key={index}>{text}</p>
                    ))}
                    <ul>
                      {_.map(list, (item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                    {_.map(price, (item, index) => (
                      <p key={index} className="price">
                        {item}
                      </p>
                    ))}
                    <div className="note">
                      {note.split(/\n/i).map((text, index) => (
                        <p key={index}>{text}</p>
                      ))}
                    </div>
                  </div>
                );
              })}
        </div>
        <Logo />
      </div>
    </div>
  );
}

export default withGoogleSheets("Pakiety")(PakietyPage);
