import React from "react";
import { withGoogleSheets } from "../components/withGoogleSheets";
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import _ from "lodash";

import img1 from "../assets/okolica/1.jpg";
import img2 from "../assets/okolica/2.jpg";
import img3 from "../assets/okolica/3.jpg";
import img4 from "../assets/okolica/4.jpg";
import img5 from "../assets/okolica/5.jpg";
import img6 from "../assets/okolica/6.jpg";
import img7 from "../assets/okolica/7.jpg";
import markerIcon from "../assets/marker.png";

import ImageGallery from "../components/gallery";
import Logo from '../components/logo';

import './okolica.scoped.scss';

function OkolicaPage(props) {
  const images = _.map([img1, img2, img3, img4, img5, img6, img7], (original) => ({
    original,
  }));
  const lat = _.get(_.find(props.db.Okolica, { id: "lat" }), "value");
  const lng = _.get(_.find(props.db.Okolica, { id: "lng" }), "value");

  return (
    <div>
      <div className="media-content">
        <h2>
          {_.get(_.find(props.db.Okolica, { id: "header" }), "value")}
        </h2>
        <ImageGallery items={images} />
      </div>
      <div className="container text-content">
        <div className="container-text">
        {_.find(props.db.Okolica, { id: "main" })
            .value.split(/\n/i)
            .map((text, index) => (
              <p key={index}>{text}</p>
            ))}
        </div>
        <Logo/>
      </div>
      <div className="mapa container">
          <Map google={props.google} initialCenter={{lat, lng}} zoom={12}>
              <Marker
                position={{lat, lng}}
                icon={markerIcon}
              />
          </Map>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({apiKey: 'AIzaSyCQAdgMFsQONbkXLkbD9gydzPK-Bq-Ana0'})(withGoogleSheets("Okolica")(OkolicaPage));
