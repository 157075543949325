import React from 'react';

import './App.scoped.scss';
import './styles/global.scss';
import HomePage from './pages/home';
import HistoriaPage from './pages/historia';
import KontaktPage from './pages/kontakt';
import OkolicaPage from './pages/okolica';
import PakietyPage from './pages/pakiety';
import PokojePage from './pages/pokoje';
import RestauracjaPage from './pages/restauracja';
import RezerwacjaPage from './pages/rezerwacja';
import SpotkaniaPage from './pages/spotkania';

import Menu from './components/menu';
import Footer from './components/footer';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


function App(props) {
  return (
    <div className="App">
      <Router>
        <Menu/>
        <article className="content">
          <Switch>
            <Route path='/historia'>
              <HistoriaPage/>
            </Route>
            <Route path='/kontakt'>
              <KontaktPage/>
            </Route>
            <Route path='/okolica'>
              <OkolicaPage/>
            </Route>
            <Route path='/pakiety'>
              <PakietyPage/>
            </Route>
            <Route path='/pokoje'>
              <PokojePage/>
            </Route>
            <Route path='/restauracja'>
              <RestauracjaPage/>
            </Route>
            <Route path='/rezerwacja'>
              <RezerwacjaPage/>
            </Route>
            <Route path='/spotkania'>
              <SpotkaniaPage/>
            </Route>
            <Route>
              <HomePage/>
            </Route>
          </Switch>
        </article>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
