import React from "react";
import { withGoogleSheets } from "../components/withGoogleSheets";
import _ from "lodash";

import img1 from "../assets/rezerwacja/1.jpg";
import img2 from "../assets/rezerwacja/2.jpg";

import ImageGallery from "../components/gallery";
import Logo from '../components/logo';


import './kontakt.scoped.scss';

function RezerwacjaPage(props) {
  const images = _.map([img1, img2], (original) => ({
    original,
  }));
  
  const phone = _.find(props.db.Contact, {id: 'phone'});

  const list = _.map(
    _.omit(_.groupBy(props.db.Contact, "position"), "null"),
    (items) => {
      return {
        name: _.find(items, { id: "name" }).value,
        ..._.find(items, { id: "link" }),
      };
    }
  );

  return (
    <div>
      <div className="media-content">
        <h2>
          {_.get(_.find(props.db.Contact, { id: "rezerwacjeHeader" }), "value")}
        </h2>
        <ImageGallery items={images} />
      </div>
      <div className="container text-content">
        <div className="container-text address-container">
            <div className="address">
            <p><span className="name">{_.get(_.find(props.db.Contact, {id: 'phoneName'}), 'value')}:</span><a href={`tel:${phone.href}`}>{phone.value}</a></p>
            {_.map(list, ({ name, value, href }) => {
                return (
                    <p key={name}>
                        <span className="name">{name}:</span>
                        <a href={`tel:${href}`}>{value}</a>
                    </p>
                );
                }
            )}
        </div>
        </div>
        <Logo/>
      </div>
    </div>
  );
}

export default withGoogleSheets("Contact")(RezerwacjaPage);
