import React, { useState } from "react";
import _ from 'lodash';
import { withGoogleSheets } from "../components/withGoogleSheets";
import { NavLink, Link } from "react-router-dom";
import classnames from "classnames";
import "./menu.scoped.scss";
import logo from '../assets/logo-xs.png';

import burger from '../assets/burger.png';

export default withGoogleSheets("Menu")((props) => {
  const [dropdownOpen, setDropdown] = useState(false);
  const xsRezerwacja = _.find(props.db.Menu, {special: '1'});

  // {props.db.Menu.map(({ link, value, special }) => (
  //   <NavLink exact key={value} to={link} className={classnames("link", value, {special: special === '1'})} title={value}>
  //     {value}
  //   </NavLink>
  // ))}
  console.log(props.db.Menu);
  
  return (
    <nav className="nav-container">
      <div className="nav container">
        <Link to='/' className="hidden-xs">
          <img src={logo} alt="Hotel Villiam logo" className="logo"/>
        </Link>
        <div className="burger visible-xs" onClick={() => setDropdown(!dropdownOpen)}>
          <img src={burger} alt="Menu"/>
        </div>
        <NavLink exact key={xsRezerwacja.value} to={xsRezerwacja.link} className={classnames("link special visible-xs", xsRezerwacja.value)} title={xsRezerwacja.value}>
          {xsRezerwacja.value}
        </NavLink>
        <div className="nav-list hidden-xs">
          {props.db.Menu.filter(({disabled}) => disabled !== "TRUE").map(({ link, value, special }) => (
            <NavLink exact key={value} to={link} className={classnames("link", value, {special: special === '1', 'hidden-xs': special === '1'})} title={value}>
              {value}
            </NavLink>
          ))}
        </div>
      </div>
      <div className={`nav-list dropdown ${dropdownOpen ? 'open' : null}`}>
          {props.db.Menu.map(({ link, value, special }) => (
            <NavLink exact key={value} to={link} className={classnames("link", value, {special: special === '1', 'hidden-xs': special === '1'})} title={value} onClick={() => setDropdown(!dropdownOpen)}>
              {value}
            </NavLink>
          ))}
        </div>
    </nav>
  );
});
