import React, { Fragment } from "react";
import { withGoogleSheets } from "../components/withGoogleSheets";
import _ from "lodash";

import img1 from "../assets/restauracja/1.jpg";
import img2 from "../assets/restauracja/2.jpg";
import img3 from "../assets/restauracja/3.jpg";
import img4 from "../assets/restauracja/4.jpg";
import img5 from "../assets/restauracja/5.jpg";
import img6 from "../assets/restauracja/6.jpg";
import img7 from "../assets/restauracja/7.jpg";

import ImageGallery from "../components/gallery";
import Logo from '../components/logo';

function RestauracjaPage(props) {
  const images = _.map([img1, img2, img3, img4, img5, img6, img7], (original) => ({
    original,
  }));

  return (
    <Fragment>
      <div className="media-content">
        <h2>
          {_.get(_.find(props.db.Restauracja, { id: "header" }), "value")}
        </h2>
        <ImageGallery items={images} />
      </div>
      <div className="container text-content">
        <div className="container-text">
        {_.find(props.db.Restauracja, { id: "main" })
            .value.split(/\n/i)
            .map((text, index) => (
              <p key={index}>{text}</p>
            ))}
        </div>
        <Logo/>
      </div>
    </Fragment>
  );
}

export default withGoogleSheets("Restauracja")(RestauracjaPage);
