import React, { Fragment } from "react";
import { withGoogleSheets } from "../components/withGoogleSheets";
import _ from "lodash";


import img1 from "../assets/spotkania/1.jpg";
import img2 from "../assets/spotkania/2.jpg";

import ImageGallery from "../components/gallery";
import Logo from '../components/logo';

function SpotkaniaPage(props) {
  const images = [
    {
      original: img1,
    },
    {
      original: img2,
    },
  ];
  
  return (
    <Fragment>
      <div className="media-content">
        <h2>{_.get(_.find(props.db.Spotkania, { id: "header" }), "value")}</h2>
        <ImageGallery
          items={images}
        />
      </div>
      <div className="container text-content">
        <div className="container-text">
          {_.find(props.db.Spotkania, { id: "main" })
            .value.split(/\n/i)
            .map((text, index) => (
              <p key={index}>{text}</p>
            ))}
        <ul>
          {_.filter(props.db.Spotkania, { id: "list" })
            .map(({value}, index) => (
              <li key={index}>{value}</li>
            ))}
        </ul>
        <p dangerouslySetInnerHTML={{__html: _.find(props.db.Spotkania, {id: 'last'}).value}}/>
        </div>
        <Logo/>
      </div>
    </Fragment>
  );
}

export default withGoogleSheets("Spotkania")(SpotkaniaPage);
