import React from "react";
import ImageGallery from "react-image-gallery";
import classnames from "classnames";

import './gallery.scoped.scss';

import { ReactComponent as Arrow } from '../assets/arrow.svg';

export default (props) => {
    const iconSize = 42;
    const renderLeftNav = (onClick, disabled) => {
        return (
            <Arrow
                width={iconSize}
                height={iconSize}
            className={classnames('arrow-left', {
                disabled
            })}
            onClick={onClick}/>
        )
    }
    const renderRightNav = (onClick, disabled) => {
        return (
            <Arrow
                width={iconSize}
                height={iconSize}
            className={classnames('arrow-right', {
                disabled
            })}
            onClick={onClick}/>
        )
    }
    return <ImageGallery
        {...props}
        showNav={true}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
    />
}