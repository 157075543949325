import React from "react";
import { withGoogleSheets } from "../components/withGoogleSheets";
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import _ from "lodash";

import img1 from "../assets/kontakt/1.jpg";
import img2 from "../assets/kontakt/2.jpg";

import ImageGallery from "../components/gallery";
import Logo from '../components/logo';

import markerIcon from "../assets/marker.png";


import './okolica.scoped.scss';
import './kontakt.scoped.scss';

function KontaktPage(props) {
  const images = _.map([img1, img2], (original) => ({
    original,
  }));

  const phone = _.find(props.db.Contact, {id: 'phone'});

  const list = _.map(
    _.omit(_.groupBy(props.db.Contact, "position"), "null"),
    (items) => {
      return {
        name: _.find(items, { id: "name" }).value,
        ..._.find(items, { id: "link" }),
      };
    }
  );

  const lat = _.get(_.find(props.db.Okolica, { id: "lat" }), "value");
  const lng = _.get(_.find(props.db.Okolica, { id: "lng" }), "value");

  return (
    <div>
      <div className="media-content">
        <h2>
          {_.get(_.find(props.db.Contact, { id: "header" }), "value")}
        </h2>
        <ImageGallery items={images} />
      </div>
      <div className="container text-content">
        <div className="container-text address-container">
            <p>{_.get(_.find(props.db.Contact, {id: 'name'}), 'value')}</p>
            <address className="address">
            <p>
                <span className="name">{_.get(_.find(props.db.Contact, {id: 'addressName'}), 'value')}:</span>
                <span>{_.get(_.find(props.db.Contact, {id: 'city'}), 'value')}, {_.get(_.find(props.db.Contact, {id: 'street'}), 'value')}</span>
            </p>
                <p><span className="name">{_.get(_.find(props.db.Contact, {id: 'phoneName'}), 'value')}:</span><a href={`tel:${phone.href}`}>{phone.value}</a></p>
                {_.map(list, ({ name, value, href, type }) => {
                    return (
                        <p key={value}>
                            <span className="name">{name}:</span>
                            <a href={`${type || 'tel'}:${href}`}>{value}</a>
                        </p>
                    );
                    }
                )}
        </address>
        </div>
        <Logo/>
      </div>
      <div className="mapa container">
          <Map google={props.google} initialCenter={{lat, lng}} zoom={12}>
              <Marker
                position={{lat, lng}}
                icon={markerIcon}
              />
          </Map>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({apiKey: 'AIzaSyCQAdgMFsQONbkXLkbD9gydzPK-Bq-Ana0'})(withGoogleSheets(["Contact", "Okolica"])(KontaktPage));
