import React, { Fragment } from "react";
import { withGoogleSheets } from "../components/withGoogleSheets";
import _ from "lodash";
import poster from "../assets/media.png";
import Logo from '../components/logo';

function HomePage(props) {
  return (
    <Fragment>
      <div className="media-content">
        <video
          autoPlay
          loop
          playsInline
          muted={true}
          poster={poster}
          title="Hotel villam - perła Bielawy"
          className="intro-video"
        >
          <source
            src={process.env.PUBLIC_URL + "/intro.mp4"}
            type="video/mp4"
          />
          <source
            src={process.env.PUBLIC_URL + "/intro.webpm"}
            type="video/webm"
          />
          <source
            src={process.env.PUBLIC_URL + "/intro.ogg"}
            type="video/ogg"
          />
        </video>
      </div>
      <div className="container text-content">
        <div className="container-text">
          {_.find(props.db.Home, { id: "main" })
            .value.split(/\n/i)
            .map((text, index) => (
              <p key={index}>{text}</p>
            ))}
        <Logo/>
        </div>
      </div>
    </Fragment>
  );
}

export default withGoogleSheets("Home")(HomePage);
