import './react-app-env.d';
import React from 'react';
import {hydrate, render} from "react-dom";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import GoogleSheetsProvider from 'react-db-google-sheets';
require('dotenv').config()

// if (rootElement.hasChildNodes())
const rootElement = document.getElementById("root");

const method = rootElement.hasChildNodes() ?  hydrate : render;


method(
  <React.Fragment>
    {
      process.env.NODE_ENV !== 'production' ?
        <GoogleSheetsProvider>
          <App />
        </GoogleSheetsProvider>
      :
        <App/>
    }
  </React.Fragment>,
  rootElement
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
