import React from 'react';
import _ from 'lodash';
import { withGoogleSheets } from '../components/withGoogleSheets';

import ImageGallery from "../components/gallery";

import './pokoje.scoped.scss';
import './pokoje.scss';

import bed from '../assets/bed.png';
import people from '../assets/people.png';
import m2 from '../assets/m2.png';

const images = {
    header: [
        require('../assets/pokoje/1.jpg'),
    ],
    classic: [
        require('../assets/pokoje/classic/1.jpg'),
        require('../assets/pokoje/classic/2.jpg')
    ],
    family: [
        require('../assets/pokoje/family/1.jpg'),
        require('../assets/pokoje/family/2.jpg')
    ],
    grand: [
        require('../assets/pokoje/grand/1.jpg'),
        require('../assets/pokoje/grand/2.jpg'),
        require('../assets/pokoje/grand/3.jpg'),
        require('../assets/pokoje/grand/4.jpg'),
    ],
    villam: [
        require('../assets/pokoje/villam/1.jpg'),
        require('../assets/pokoje/villam/2.jpg'),
        require('../assets/pokoje/villam/3.jpg'),
        require('../assets/pokoje/villam/4.jpg'),
    ],
    vision: [
        require('../assets/pokoje/vision/1.jpg'),
        require('../assets/pokoje/vision/2.jpg'),
        require('../assets/pokoje/vision/3.jpg'),
        require('../assets/pokoje/vision/4.jpg'),
    ],
};

const mapImages = (imgs) => imgs.map((img) => ({original: img}));

function PokojePage (props) {
    const pokoje = _.map(
        _.omit(_.groupBy(props.db.Pokoje, "pokoj"), "null"),
        (items) => {
          return {
            key: _.head(items).pokoj,
            header: _.find(items, { id: "header" }).value,
            imgFolder: _.find(items, { id: "imgFolder" }).value,
            name: _.find(items, { id: "name" }).value,
            multi: _.find(items, { id: "multi" }).value,
            size: _.find(items, { id: "size" }).value,
            bed: _.find(items, { id: "bed" }).value,
            people: _.find(items, { id: "people" }).value,
            description: _.find(items, { id: "description" }).value,
            items: _.map(_.filter(items, { id: "items" }), "value"),
          };
        }
      );
      
    return (
        <div>
            <div className="media-content">
                <h2 dangerouslySetInnerHTML={{__html:_.get(_.find(props.db.Pokoje, { id: "header" }), "value")}} />
                <ImageGallery items={mapImages(images.header)} />
            </div>
            <div className="container text-content">
                <div className="container-text">
                    <h3>{_.get(_.find(props.db.Pokoje, { id: "title" }), "value")}</h3>
                    { _.filter(props.db.Pokoje, { id: "lead" }).map(({value}, index) => <p key={index} dangerouslySetInnerHTML={{__html: value}}/>) }
                    <div className="item-list">
                        {_.get(_.find(props.db.Pokoje, { id: "list-header" }), "value")}
                        <ul>
                            { _.filter(props.db.Pokoje, { id: "list" }).map(({value}, index) => <li key={index} dangerouslySetInnerHTML={{__html: value}}/>) }
                        </ul>
                    </div>
                </div>
            </div>
            {
                pokoje.map((pokoj) => {
                    return (
                        <div className="container pokoj" key={pokoj.key}>
                            <h4>{pokoj.header}</h4>
                            <div className="media-content">
                                <ImageGallery additionalClass="pokoj-gallery" items={mapImages(images[pokoj.imgFolder])} />
                            </div>
                            <div className="row">
                                <div className="description">
                                    <header>{pokoj.name}</header>
                                    <div className="description-item">
                                        <img src={m2} alt=""/>
                                        <p>{_.get(_.find(props.db.Pokoje, { id: "roomSizeDescription" }), "value")} {pokoj.multi} {pokoj.size}</p>
                                    </div>
                                    <div className="description-item">
                                        <img src={bed} alt=""/>
                                        <p>{_.get(_.find(props.db.Pokoje, { id: "roomBedsDescription" }), "value")} - {pokoj.bed}</p>
                                    </div>
                                    <div className="description-item">
                                        <img src={people} alt=""/>
                                        <p>{_.get(_.find(props.db.Pokoje, { id: "roomPeopleDescription" }), "value")} - {pokoj.people}</p>
                                    </div>
                                    <p className="description-long">{pokoj.description}</p>
                                    <a className="button hidden-xs" href={_.get(_.find(props.db.Pokoje, { id: "actionTarget" }), "value")}>{_.get(_.find(props.db.Pokoje, { id: "action" }), "value")}</a>
                                </div>
                                <div className="items item-list">
                                    <p className="list-header">
                                        {_.get(_.find(props.db.Pokoje, { id: "itemsListDescription" }), "value")}
                                    </p>
                                    <ul>
                                        { pokoj.items.map((value, index) => <li key={index} dangerouslySetInnerHTML={{__html: value}}/>) }
                                    </ul>
                                </div>
                                <a className="button visible-xs" href={_.get(_.find(props.db.Pokoje, { id: "actionTarget" }), "value")}>{_.get(_.find(props.db.Pokoje, { id: "action" }), "value")}</a>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default withGoogleSheets('Pokoje')(PokojePage);