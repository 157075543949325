import React from 'react';
import { withGoogleSheets as original } from "react-db-google-sheets";
import data from '../assets/db.json';
import _ from 'lodash';

export const withGoogleSheets = (sheets) => (WrappedComponent) => {
    return class extends React.Component {
        render() {
            if (process.env.NODE_ENV !== 'production') {
                return React.createElement(original(sheets)(WrappedComponent), this.props);
            }
            const props = {...this.props, db: _.pick(data, sheets)};
            return <WrappedComponent  {...props}/>;
        }
    }
}