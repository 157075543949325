import React from "react";
import { withGoogleSheets } from "../components/withGoogleSheets";
import _ from "lodash";

import img1 from "../assets/historia/1.jpg";
import img2 from "../assets/historia/2.jpg";

import ImageGallery from "../components/gallery";
import Logo from '../components/logo';

function HistoriaPage(props) {
  const images = _.map([img1, img2], (original) => ({
    original,
  }));

  return (
    <div className="history-page">
      <div className="media-content">
        <h2>
          {_.get(_.find(props.db.Historia, { id: "header" }), "value")}
        </h2>
        <ImageGallery items={images} />
      </div>
      <div className="container text-content">
        <div className="container-text">
        {_.find(props.db.Historia, { id: "main" })
            .value.split(/\n/i)
            .map((text, index) => (
              <p key={index}>{text}</p>
            ))}
        </div>
        <Logo/>
      </div>
    </div>
  );
}

export default withGoogleSheets("Historia")(HistoriaPage);
